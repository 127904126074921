const APPROVED_STATES = [
	{ name: 'Alabama', abbr: 'AL' },
	{ name: 'Alaska', abbr: 'AK' },
	{ name: 'Arkansas', abbr: 'AR' },
	{ name: 'Arizona', abbr: 'AZ' },
	{ name: 'Colorado', abbr: 'CO' },
	{ name: 'Connecticut', abbr: 'CT' },
	{ name: 'District of Columbia', abbr: 'DC' },
	{ name: 'Delaware', abbr: 'DE' },
	{ name: 'Florida', abbr: 'FL' },
	{ name: 'Georgia', abbr: 'GA' },
	{ name: 'Hawaii', abbr: 'HI' },
	{ name: 'Illinois', abbr: 'IL' },
	{ name: 'Indiana', abbr: 'IN' },
	{ name: 'Iowa', abbr: 'IA' },
	{ name: 'Kansas', abbr: 'KS' },
	{ name: 'Kentucky', abbr: 'KY' },
	{ name: 'Louisiana', abbr: 'LA' },
	{ name: 'Maine', abbr: 'ME' },
	{ name: 'Michigan', abbr: 'MI' },
	{ name: 'Minnesota', abbr: 'MN' },
	{ name: 'Mississippi', abbr: 'MS' },
	{ name: 'Missouri', abbr: 'MO' },
	{ name: 'Montana', abbr: 'MT' },
	{ name: 'North Carolina', abbr: 'NC' },
	{ name: 'North Dakota', abbr: 'ND' },
	{ name: 'Nebraska', abbr: 'NE' },
	{ name: 'New Hampshire', abbr: 'NH' },
	{ name: 'New Jersey', abbr: 'NJ' },
	{ name: 'New Mexico', abbr: 'NM' },
	{ name: 'Nevada', abbr: 'NV' },
	{ name: 'Oklahoma', abbr: 'OK' },
	{ name: 'Oregon', abbr: 'OR' },
	{ name: 'Pennsylvania', abbr: 'PA' },
	{ name: 'Rhode Island', abbr: 'RI' },
	{ name: 'South Carolina', abbr: 'SC' },
	{ name: 'South Dakota', abbr: 'SD' },
	{ name: 'Tennessee', abbr: 'TN' },
	{ name: 'Texas', abbr: 'TX' },
	{ name: 'Utah', abbr: 'UT' },
	{ name: 'Vermont', abbr: 'VT' },
	{ name: 'Washington', abbr: 'WA' },
	{ name: 'Wisconsin', abbr: 'WI' },
	{ name: 'West Virginia', abbr: 'WV' },
	{ name: 'Wyoming', abbr: 'WY' },
];

// CA, ID, MD, MA, NY, OH VA
const UNAPPROVED_STATES = [
	{ name: 'California', abbr: 'CA' },
	{ name: 'Idaho', abbr: 'ID' },
	{ name: 'Massachusetts', abbr: 'MA' },
	{ name: 'Maryland', abbr: 'MD' },
	{ name: 'New York', abbr: 'NY' },
	{ name: 'Ohio', abbr: 'OH' },
	{ name: 'Virginia', abbr: 'VA' },
];

// TODO: remove one of these and update files that depend on them.
const APPROVED_STATES_ARR = APPROVED_STATES.map((state) => state.abbr);

const PAYMENT_TYPE = {
	PAYMENTS: { label: 'Monthly', value: 'PAYMENTS' },
	SINGLE_PAYMENT: { label: 'One-time', value: 'SINGLE_PAYMENT' },
};

const WHO_TYPE = {
	ME: { label: 'Just Me', value: 'ME' },
	ME_AND_SPOUSE: { label: 'Me and my spouse', value: 'ME_AND_SPOUSE' },
	FAMILY: { label: 'My Family', value: 'FAMILY' },
	KIDS: { label: 'Just My kids', value: 'KIDS' },
};

const DURATION_TYPE = {
	YEAR: { value: 'YEAR', label: 'Annual' },
	MONTH: { value: 'MONTH', label: 'Month' },
	WEEK: { value: 'WEEK', label: 'Week' },
	DAY: { value: 'DAY', label: 'Day' },
	MANUAL: { value: 'MANUAL', label: 'Specific Dates' },
};

const COVERED_TYPE = {
	ME: 'ME',
	SPOUSE: 'SPOUSE',
	KID: 'KID',
};

const POLICY_STATUS = {
	ACTIVE: 'ACTIVE',
	CANCELLED: 'CANCELLED',
	EXPIRED: 'EXPIRED',
	NOT_STARTED: 'NOT_STARTED',
	CANCELLED_BEFORE_USE: 'CANCELLED_BEFORE_USE',
	RESERVATION: 'RESERVATION',
	CANCELLED_RESERVATION: 'CANCELLED_RESERVATION',
};

// states with special DOCs
const SPECIAL_STATES = [
	'AK',
	'AR',
	'CO',
	'CT',
	'DC',
	'DE',
	'IL',
	'KS',
	'KY',
	'LA',
	'MI',
	'MO',
	'MN',
	'MT',
	'NC',
	'NH',
	'NJ',
	'OH',
	'OK',
	'OR',
	'PA',
	'SD',
	'TN',
	'UT',
	'VT',
	'WA',
	'WY',
];

const POLICY_TYPE = {
	ACCIDENT: 'BUDDY_ACCIDENT_V2',
	ITC: 'ITC_TICKET_CANCELLATION',
	AAC: 'AAC_AD_AND_D',
	VIGILANCE: 'VIGILANCE_OCCUPATIONAL_ACCIDENT',
};

const PORTAL_URL = {
	TEST: 'portal.buddy-testing.insure',
	LOCAL: 'portal.buddy-testing.insure',
	DEVELOPMENT: 'portal.buddy-testing.insure',
	TESTING: 'portal.buddy-testing.insure',
	STAGING: 'staging.portal.buddy.insure',
	PRODUCTION: 'portal.buddy.insure',
};

const CARRIER_POLICIES = [
	{
		title: 'Berkley Accident & Health',
		subdomain: 'berkleyah',
		policies: [
			'Vigilance Occupational Accident',
			'Buddy On-Demand Accident',
			'Buddy Ticket/Registration Cancellation',
		],
	},
	{
		title: 'pomi',
		subdomain: 'pomi',
		policies: [
			'pomi Accident',
			'pomi Season Interruption',
		],
	},
	{
		title: 'Starr',
		subdomain: 'starr',
		policies: [
			'AAC Accidental Death and Dismemberment',
		],
	},
];

export {
	APPROVED_STATES,
	UNAPPROVED_STATES,
	APPROVED_STATES_ARR,
	COVERED_TYPE,
	DURATION_TYPE,
	POLICY_STATUS,
	SPECIAL_STATES,
	PAYMENT_TYPE,
	POLICY_TYPE,
	WHO_TYPE,
	CARRIER_POLICIES,
	PORTAL_URL,
};

// eslint-disable-next-line no-unused-vars
import React from 'react';
import { PORTAL_URL } from '../models/SimpleModels';

const Customers = () => {
	// This will throw if the GATSBY_ENV is not set, which is a good thing. We want to know if it's not set and thus not loading a URL.
	const portalUrl = PORTAL_URL[process.env.GATSBY_ENV.toUpperCase()];
	if (typeof window !== 'undefined') {
		const { search } = window.location;
		const partner = new URLSearchParams(search).get('partner');
		if (partner?.toLowerCase() === 'pomi') {
			window.location.href = `https://pomi.${portalUrl}`;
			return null;
		}
		window.location.href = '/';
	}
	return null;
};

export default Customers;
